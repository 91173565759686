import { Controller } from "stimulus";

export default class extends Controller {
  boundScroll: any;

  connect() {
    this.boundScroll = this.onScroll.bind(this);
    this.onScroll();
    window.addEventListener("scroll", this.boundScroll, { passive: true });
  }

  disconnect() {
    window.removeEventListener("scroll", this.boundScroll);
  }

  onScroll() {
    const button = this.element as HTMLElement;

    const condition = window.scrollY <= 20;
  }

  toTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
