import { Controller } from "stimulus";

class SharedRangeController extends Controller {
  public valueTarget!: HTMLElement;
  public buttonTarget!: HTMLElement;
  public rangeTarget!: HTMLElement;
  public containerTarget!: HTMLElement;
}

export default class extends (Controller as typeof SharedRangeController) {
  static targets = ["value", "button", "range", "container"];
  value: any = { min: 0.0, max: 0.0 };
  boundDrag: any;
  boundDragEnd: any;
  timeout: any;

  connect() {
    this.boundDrag = this.drag.bind(this);
    this.boundDragEnd = this.stopDrag.bind(this);
    const container = this.element as HTMLElement;
    this.value["min"] = Number(container.dataset["min"]) || 0;
    this.value["max"] = Number(container.dataset["max"]) || this.value["min"];
    if (this.value["min"] > this.value["max"]) {
      this.value["max"] = this.value["min"];
    }
    window.addEventListener("mousemove", this.boundDrag);
    window.addEventListener("touchmove", this.boundDrag);
    window.addEventListener("mouseup", this.boundDragEnd);
    window.addEventListener("touchend", this.boundDragEnd);
    container.addEventListener("rangeupdated", (e: any) => this.init(e.detail));
  }

  disconnect() {
    window.removeEventListener("mousemove", this.boundDrag);
    window.removeEventListener("touchmove", this.boundDrag);
    window.removeEventListener("mouseup", this.boundDragEnd);
    window.removeEventListener("touchend", this.boundDragEnd);
  }

  init(obj: any) {
    const left = (obj["value"] * 100) / this.value["max"];
    this._updatePosition(left);
  }

  startDrag(e: any) {
    const target = (e.target || e.toElement) as HTMLElement;
    if (!target) {
      e.preventDefault();
      return;
    }
    this.buttonTarget.dataset["active"] = "1";
    this.drag(e);
  }

  drag(e: any) {
    if (this.timeout) return;

    let target;
    if (this.buttonTarget.dataset["active"] === "1") {
      target = this.buttonTarget;
    }
    if (!target || target.dataset["active"] !== "1") {
      return;
    }
    let posX = e.clientX || 0;
    if (e.type === "touchmove") {
      const evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
      const touch = evt.touches[0] || evt.changedTouches[0];
      posX = touch.pageX;
    }

    const range = this.rangeTarget.getBoundingClientRect();
    const left = ((posX - range.left) * 100) / range.width;
    this._updatePosition(left);

    this.timeout = setTimeout(() => {
      this.timeout = null;
    }, 20);
  }

  stopDrag(e: any) {
    this.buttonTarget.dataset["active"] = "";
  }

  click(e: any) {
    this.buttonTarget.dataset["active"] = "1";
    this.drag(e);
  }

  _updatePosition(left: number) {
    let maxLeft = Number(
      this.buttonTarget.style.left.replace("%", "").replace("px", "")
    );
    if (left < 0) {
      left = 0;
    } else if (left > 100) {
      left = 100;
    }

    const value =
      this.value["max"] !== 0
        ? ((this.value["max"] * left) / 100).toFixed()
        : "0";

    if (left < this.value["min"]) {
      return;
    }
    maxLeft = left;
    this.valueTarget.innerText = value;
    this.buttonTarget.style.left = `${left}%`;
    (this.element as HTMLElement).dataset["value"] = `${value}`;
  }
}
