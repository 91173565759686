import { Controller } from "stimulus";
import { isTouchDevice } from "../../utils";

class MenuController extends Controller {
  public modalTarget!: HTMLElement;
}

export default class extends (Controller as typeof MenuController) {
  static targets = ["modal"];

  connect() {
    const overlay = document.querySelector(".modal-overlay") as HTMLElement;
    if (overlay) {
      overlay.addEventListener("click", this.closeModal.bind(this));
    }
  }

  disconnect() {
    this.closeModal();
  }

  toggleModal(e: any) {
    if (e.target.tagName === "A") {
      return;
    }

    e.preventDefault();
    const menuItemsContainer = e.target.closest(
      ".menu-items-container"
    ) as HTMLElement;
    if (menuItemsContainer) return;

    if (this.modalTarget.classList.contains("is-active")) {
      this.closeModal();
    } else {
      this.openModal();
    }
  }

  openModal() {
    this.modalTarget.classList.add("is-active");
    document.body.classList.add("overflow");
    const navbar = document.querySelector("header > nav")! as HTMLElement;
    navbar.style.zIndex = "100000";

    const h = document.querySelector("html");
    if (h) {
      h.style.overflow = "hidden";
    }

    const overlay = document.querySelector(".modal-overlay") as HTMLElement;
    if (overlay) {
      overlay.classList.add("flex");
      overlay.classList.remove("hidden");
    }
  }

  closeModal() {
    this.modalTarget.classList.remove("is-active");
    document.body.classList.remove("overflow");
    const navbar = document.querySelector("header > nav")! as HTMLElement;
    navbar.style.zIndex = "";

    // This is a hack to force the browser to repaint the navbar
    window.scrollTo({ top: window.scrollY + 1, behavior: "smooth" });

    const h = document.querySelector("html");
    if (h) {
      h.style.overflow = "";
    }

    const overlay = document.querySelector(".modal-overlay") as HTMLElement;
    if (overlay) {
      overlay.classList.remove("flex");
      overlay.classList.add("hidden");
    }

    const menuContainer = document.querySelector(".menu-container");
    const itemsContainer = menuContainer?.querySelector(
      ".menu-items-container"
    );
    const categories = menuContainer?.querySelector(".menu-items-categories");
    const subcategories = menuContainer?.querySelector(
      ".menu-items-subcategories"
    );

    if (subcategories) {
      subcategories.classList.add("hidden");
      subcategories.innerHTML = "";
      itemsContainer?.appendChild(subcategories);
    }

    if (categories) {
      categories.classList.add("hidden");
      categories.innerHTML = "";
      itemsContainer?.appendChild(categories);
    }

    menuContainer
      ?.querySelectorAll(
        ".menu-items-supercategories > .menu-item .menu-item__icon i, .menu-items-categories > .menu-item .menu-item__icon i"
      )
      .forEach((el) => {
        el.classList.remove("fa-angle-double-down");
        el.classList.add("fa-angle-double-right");
      });
  }

  mouseoverHandler(e: any) {
    if (isTouchDevice()) return;

    const target = e.target as HTMLElement;
    if (target.classList.contains("menu-wrapper")) {
      this.openModal();
    } else if (target.classList.contains("menu-item__content_supercategory")) {
      this.toggleCategories(e);
    } else if (target.classList.contains("menu-item__content_category")) {
      this.toggleSubcategories(e);
    }
  }

  clickHandler(e: any) {
    const target = e.target as HTMLElement;
    if (!target) return;

    const container = target.closest(".menu-item__content") as HTMLElement;

    if (target.classList.contains("menu-wrapper")) {
      const href = target.dataset["href"];
      if (href) {
        window.location.href = href;
      }
    } else if (
      target.classList.contains("menu-item__content_supercategory") ||
      container?.classList.contains("menu-item__content_supercategory")
    ) {
      this.openSupercategory(e);
    } else if (
      target.classList.contains("menu-item__content_category") ||
      container?.classList.contains("menu-item__content_category")
    ) {
      this.openCategory(e);
    } else if (
      target.classList.contains("menu-item__content_subcategory") ||
      container?.classList.contains("menu-item__content_subcategory")
    ) {
      this.openSubcategory(e);
    }
  }

  toggleCategories(e: any) {
    if (isTouchDevice() && e.type === "mouseover") return;

    const item = e.target.closest(".menu-item") as HTMLElement;

    if (!item) return;

    const icon = item.querySelector(".menu-item__icon i") as HTMLElement;
    const menuContainer = item.closest(".menu-container");
    const itemsContainer = menuContainer?.querySelector(
      ".menu-items-container"
    );
    const categories = menuContainer?.querySelector(".menu-items-categories");
    const subcategories = menuContainer?.querySelector(
      ".menu-items-subcategories"
    );

    if (subcategories) {
      subcategories.classList.add("hidden");
      subcategories.innerHTML = "";
      itemsContainer?.appendChild(subcategories);
    }

    if (categories) {
      categories.classList.add("hidden");
      categories.innerHTML = "";
      itemsContainer?.appendChild(categories);
    }

    const isOpened = icon?.classList.contains("fa-angle-double-down");
    menuContainer
      ?.querySelectorAll(
        ".menu-items-supercategories > .menu-item .menu-item__icon i, .menu-items-categories > .menu-item .menu-item__icon i"
      )
      .forEach((el) => {
        el.classList.remove("fa-angle-double-down");
        el.classList.add("fa-angle-double-right");
      });

    if (isOpened) return;

    let data: any;
    try {
      data = JSON.parse(item.dataset["data"] || "[]");
    } catch (_) {}

    if (!data || data?.length === 0) return;

    if (categories) {
      if (icon) {
        icon.classList.remove("fa-angle-double-right");
        icon.classList.add("fa-angle-double-down");
      }
      categories.classList.remove("hidden");

      categories.innerHTML = data
        .map((category: any) => {
          const div = document.createElement("div");
          div.classList.add("menu-item");
          div.dataset["data"] = JSON.stringify(category.subcategories);
          div.innerHTML = `<div class="menu-item__content menu-item__content_category" data-href="${category.link}">${category.title} <span class="menu-item__icon"><i class="fas fa-angle-double-right"></i></span></div>`;
          return div.outerHTML;
        })
        .join("");

      if (window.innerWidth < 1280) {
        item.appendChild(categories);
      }
    }
  }

  toggleSubcategories(e: any) {
    const item = e.target.closest(".menu-item") as HTMLElement;
    if (!item) return;

    const icon = item.querySelector(".menu-item__icon i") as HTMLElement;
    const menuContainer = item.closest(".menu-container");
    const itemsContainer = menuContainer?.querySelector(
      ".menu-items-container"
    );
    const subcategories = menuContainer?.querySelector(
      ".menu-items-subcategories"
    );

    if (subcategories) {
      subcategories.classList.add("hidden");
      subcategories.innerHTML = "";
      itemsContainer?.appendChild(subcategories);
    }
    const isOpened = icon?.classList.contains("fa-angle-double-down");

    menuContainer
      ?.querySelectorAll(
        ".menu-items-categories > .menu-item .menu-item__icon i"
      )
      .forEach((el) => {
        el.classList.remove("fa-angle-double-down");
        el.classList.add("fa-angle-double-right");
      });

    if (isOpened) return;

    let data: any;
    try {
      data = JSON.parse(item.dataset["data"] || "[]");
    } catch (_) {}

    if (!data || data?.length === 0) return;

    if (subcategories) {
      if (icon) {
        icon.classList.remove("fa-angle-double-right");
        icon.classList.add("fa-angle-double-down");
      }
      subcategories.classList.remove("hidden");

      subcategories.innerHTML = data
        .map((subcategory: any) => {
          const div = document.createElement("div");
          div.classList.add("menu-item");
          div.innerHTML = `<div class="menu-item__content menu-item__content_subcategory" data-href="${subcategory.link}">${subcategory.title}</div>`;
          return div.outerHTML;
        })
        .join("");

      if (window.innerWidth < 1280) {
        item.appendChild(subcategories);
      }
    }
  }

  openSupercategory(e: any) {
    const target = e.target as HTMLElement;

    const container = target.closest(".menu-item__content") as HTMLElement;
    if (!container.classList.contains("menu-item__content_supercategory")) {
      return;
    }

    if (!target.classList.contains("menu-item__content")) {
      this.toggleCategories(e);
      return;
    }
    const href = target.dataset["href"];

    if (!href) return;

    window.location.href = href;
  }

  openCategory(e: any) {
    const target = e.target as HTMLElement;

    const container = target.closest(".menu-item__content") as HTMLElement;
    if (!container.classList.contains("menu-item__content_category")) {
      return;
    }

    if (!target.classList.contains("menu-item__content")) {
      this.toggleSubcategories(e);
      return;
    }
    const href = target.dataset["href"];

    if (!href) return;

    window.location.href = href;
  }

  openSubcategory(e: any) {
    const target = e.target as HTMLElement;

    if (
      !target.classList.contains("menu-item__content_subcategory") ||
      !target.classList.contains("menu-item__content")
    ) {
      return;
    }
    const href = target.dataset["href"];

    if (!href) return;

    window.location.href = href;
  }
}
