// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

class HeaderSliderController extends Controller {
  public trackTarget!: HTMLElement;
  public listTarget!: HTMLElement;
}

export default class extends (Controller as typeof HeaderSliderController) {
  static targets = ["track", "list"];

  connect() {
    const container = this.element as HTMLElement;
    const splideContainer = container.querySelector(
      ".splide.header-slider"
    ) as HTMLElement;
    const condition =
      splideContainer.querySelectorAll(".splide__slide").length > 1;

    document
      .querySelector(".header-slider .splide__arrows")
      ?.classList.toggle("hidden", !condition);

    new Splide(splideContainer, {
      type: "loop",
      perPage: 1,
      autoplay: true,
      arrows: condition,
      pagination: condition,
      pauseOnHover: true,
      speed: 2000,
      interval: 7000,
      classes: {
        pagination: "header-pagination flex justify-center items-center",
      },
    }).mount();
  }

  disconnect() {
    const pagination = this.element.querySelector(
      ".header-pagination"
    ) as HTMLElement;
    pagination.parentElement?.removeChild(pagination);
  }
}
