import { Controller } from "stimulus";

import videojs from "video.js";

export default class extends Controller {
  connect() {
    this.initVideo();
  }

  disconnect() {}

  private initVideo() {
    const video = this.element.querySelector("video") as HTMLVideoElement;
    if (!video) return;

    const language = document.documentElement.lang;
    const autoplay = (this.element as HTMLElement).dataset.autoplay === "1";
    const playNext = (this.element as HTMLElement).dataset.playNext === "1";
    const player = videojs(video, {
      controls: true,
      controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false,
      },
      disablePictureInPicture: true,
      enableSmoothSeeking: true,
      fullscreen: {
        options: {
          navigationUI: "hide",
        },
      },
      language: language,
      autoplay: autoplay ? "play" : false,
    });

    if (playNext && player) {
      player.on("ended", () => {
        const nextElement = this.element.nextElementSibling as HTMLElement;
        const nextVideo = nextElement?.querySelector("video");
        if (nextVideo) {
          nextElement.scrollIntoView({ behavior: "smooth" });
          const nextPlayer = videojs.getPlayer(nextVideo);
          nextPlayer.play();
        }
      });
    }
  }
}
