import { Controller } from "stimulus";

class SearchController extends Controller {
  public modalTarget!: HTMLElement;
}

export default class extends (Controller as typeof SearchController) {
  static targets = ["modal"];

  disconnect() {
    this.closeModal()
  }

  openModal(e: any) {
    if (e) {
      e.preventDefault();
    }
    this.modalTarget.classList.add("is-active");
    document.body.classList.add('overflow');
  }

  closeModal() {
    this.modalTarget.classList.remove("is-active");
    document.body.classList.remove('overflow');
  }

  search(e: any) {
    const form = e.target as HTMLFormElement
    const input = form.querySelector('input')! as HTMLInputElement
    if (e) {
      e.preventDefault();
    }
    // @ts-ignore
    window.location = `${form.action}/${input.value}`
  }
}
