import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

class CartController extends Controller {
  public viewedProductsTarget!: HTMLElement;
}

export default class extends (Controller as typeof CartController) {
  static targets = ["viewedProducts"];

  splide: Splide | undefined;
  boundResize: any;

  connect() {
    this.initViewedSlider();

    const container = document.querySelector(".cart-items") as HTMLElement;
    if (container) {
      const items = container.querySelectorAll(
        ".cart-item .product-item"
      ) as NodeListOf<HTMLElement>;
      items.forEach((item) => {
        const productId = item.dataset["itemId"];
        if (!productId) return;

        const count = localStorage.getItem(`cart-count-${productId}`);

        if (count) {
          this.updateProductCount(productId, Number(count));
        }
      });
    }

    this.boundResize = this.onResize.bind(this);
    this.onResize();
    window.addEventListener("resize", this.boundResize);
  }

  initViewedSlider() {
    const container = this.viewedProductsTarget as HTMLElement;

    if (container) {
      const itemsLength = container.querySelectorAll(".splide__slide").length;

      const getOptionsForPage = (perPage: number) => {
        return {
          perPage: perPage > itemsLength ? itemsLength : perPage,
          type: perPage > itemsLength ? "slide" : "loop",
        };
      };

      this.splide = new Splide(container, {
        perMove: 1,
        autoplay: true,
        arrows: true,
        pagination: false,
        pauseOnHover: true,
        speed: 2000,
        interval: 5000,

        ...getOptionsForPage(6),

        breakpoints: {
          820: getOptionsForPage(2),
          990: getOptionsForPage(3),
          1200: getOptionsForPage(4),
          1550: getOptionsForPage(5),
        },
      }).mount();
    }
  }

  onResize() {
    this.splide?.refresh();

    const container = this.viewedProductsTarget as HTMLElement;
    if (!container) return;
    const itemsLength = container.querySelectorAll(".splide__slide").length;

    let perPage = 6;
    if (window.innerWidth < 820) {
      perPage = 2;
    } else if (window.innerWidth < 990) {
      perPage = 3;
    } else if (window.innerWidth < 1200) {
      perPage = 4;
    } else if (window.innerWidth < 1550) {
      perPage = 5;
    }

    const condition = itemsLength > perPage;

    container
      .querySelector(".splide__arrows")
      ?.classList.toggle("hidden", !condition);
  }

  toProductPage(e: any) {
    const target = e.target;
    if (!target) return;
    if (
      target.classList.contains(".cart") ||
      target.closest(".cart") ||
      target.classList.contains(".favorite") ||
      target.closest(".favorite") ||
      target.classList.contains(".tel") ||
      target.closest(".tel") ||
      target.classList.contains(".remove-from-cart") ||
      target.closest(".remove-from-cart") ||
      target.classList.contains(".cart-item-count-container") ||
      target.closest(".cart-item-count-container")
    )
      return;

    const container =
      target.closest(".cart-item .product-item") ||
      target.closest(".splide__slide");
    if (!container) return;
    const url = container.dataset["href"];
    if (!url) return;

    window.location = url;
  }

  removeFromCart(e: any) {
    e.preventDefault();
    const lang = document.body.dataset["lang"] || "ru";
    const item = e.target.closest(".cart-item") as HTMLElement;
    const product = item?.querySelector(".product-item") as HTMLElement;

    if (!product) return;
    const productId = product.dataset["itemId"];

    fetch(`/${lang}/remove_from_cart?product_id=${productId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (item && item.parentElement) item.parentElement.removeChild(item);
        const infoItem = document.querySelector(
          ".cart-info-container .cart-info-item[data-item-id='" +
            productId +
            "']"
        ) as HTMLElement;
        if (infoItem && infoItem.parentElement)
          infoItem.parentElement.removeChild(infoItem);

        this.updateCartTotal();

        const cartCount = document.querySelector("#cart-count") as HTMLElement;
        if (cartCount) {
          cartCount.innerText =
            (document.querySelectorAll(".cart-item").length || 0) + "";
        }
      })
      .catch((err) => console.warn("error"));
  }

  increaseCount(e: any) {
    e.preventDefault();
    const item = e.target.closest(".cart-item") as HTMLElement;
    const product = item?.querySelector(".product-item") as HTMLElement;
    if (!product) return;

    const productId = product.dataset["itemId"];
    if (!productId) return;

    let count = Number(product.dataset["itemCount"]);
    if (isNaN(count) || count < 1) {
      count = 1;
    } else {
      count++;
    }

    this.updateProductCount(productId, count);
  }

  decreaseCount(e: any) {
    e.preventDefault();
    const item = e.target.closest(".cart-item") as HTMLElement;
    const product = item?.querySelector(".product-item") as HTMLElement;
    if (!product) return;

    const productId = product.dataset["itemId"];
    if (!productId) return;

    let count = Number(product.dataset["itemCount"]);
    if (isNaN(count) || count <= 1) {
      count = 1;
    } else {
      count--;
    }

    this.updateProductCount(productId, count);
  }

  private updateProductCount(productId: string, count: number) {
    const product = document.querySelector(
      '.cart-items .cart-item .product-item[data-item-id="' + productId + '"]'
    ) as HTMLElement;
    const item = product?.closest(".cart-item") as HTMLElement;
    if (!product) return;

    const infoItemCount = document.querySelector(
      ".cart-info-container .cart-info-item[data-item-id='" +
        productId +
        "'] .cart-info-item-count"
    ) as HTMLElement;

    let newCount = Number(count);
    if (isNaN(newCount) || newCount <= 1) {
      newCount = 1;
    }

    const countElement = item.querySelector(".cart-item-count") as HTMLElement;
    if (!countElement) return;

    countElement.innerText = newCount.toString();
    product.dataset["itemCount"] = newCount.toString();
    if (infoItemCount) infoItemCount.innerText = newCount.toString() + " x";

    this.updateCartTotal();

    localStorage.setItem(`cart-count-${productId}`, newCount.toString());
  }

  private updateCartTotal() {
    const container = document.querySelector(".cart-items") as HTMLElement;
    if (!container) return;

    const totalElement = document.querySelector(
      ".cart-info-price"
    ) as HTMLElement;
    if (!totalElement) return;

    let price = 0;

    const items = container.querySelectorAll(
      ".cart-item .product-item"
    ) as NodeListOf<HTMLElement>;
    items.forEach((item) => {
      let itemPrice = Number(item.dataset["itemPrice"]);
      if (isNaN(itemPrice)) itemPrice = 0;
      let itemCount = Number(item.dataset["itemCount"]);
      if (isNaN(itemCount) || itemCount < 1) itemCount = 1;
      if (itemPrice) price += itemPrice * itemCount;
    });

    totalElement.innerText = price.toString();
  }
}
