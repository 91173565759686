import { Controller } from "stimulus";

import videojs from "video.js";

export default class extends Controller {
  connect() {
    this.init();
  }

  disconnect() {}

  private init() {
    const videos = this.element.querySelector(
      ".videos-container"
    ) as HTMLVideoElement;
    if (!videos) return;

    function handleIntersection(entries: any, observer_: any) {
      if (entries?.length === 1) {
        const entry = entries[0];
        const videoItem = entry.target.closest(".video-item");
        const previousItem =
          videoItem.previousElementSibling?.querySelector("video");
        const nextItem = videoItem.nextElementSibling?.querySelector("video");

        if (entry.isIntersecting) {
          if (previousItem) {
            const player = videojs.getPlayer(previousItem);
            player.pause();
          }

          if (nextItem) {
            const player = videojs.getPlayer(nextItem);
            player.pause();
          }
        } else if (entry.intersectionRatio > 0.5) {
          if (nextItem) {
            const player = videojs.getPlayer(nextItem);
            player.play();
          }
        } else {
          if (previousItem) {
            const player = videojs.getPlayer(previousItem);
            player.play();
          }
        }
      }

      entries.forEach((entry: any) => {
        const videoItem = entry.target.closest(".video-item");
        const video = videoItem.querySelector("video");

        if (!video) return;
        const player = videojs.getPlayer(video);

        if (entry.isIntersecting) {
          player.play()?.catch((_) => {});
        } else {
          player.pause();
        }
      });
    }

    // Create a new Intersection Observer instance
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px", // No margin around the root
      threshold: 0.51, // Trigger when 51% of the element is visible
    });

    // Get all elements to be observed
    const elementsToWatch = videos.querySelectorAll(
      ".video-item"
    ) as NodeListOf<HTMLElement>;
    const firstItem = elementsToWatch[0];

    if (firstItem) {
      const video = firstItem.querySelector("video");
      if (!video) return;
      const player = videojs.getPlayer(video);
      try {
        firstItem.dataset["autoplay"] = "1";
        player.autoplay(true);
      } catch (_) {}
    }

    // Start observing each element
    elementsToWatch.forEach((element) => {
      const video = element.querySelector("video");
      if (!video) return;
      element.dataset["playNext"] = "1";
      observer.observe(element);
    });
  }
}
