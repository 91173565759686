import { Controller } from "stimulus";
import Isotope from "isotope-layout";

class SubcategoryController extends Controller {
  public modalTarget!: HTMLElement;
  public listTarget!: HTMLElement;
  public sortTargets!: HTMLElement[];
}

export default class extends (Controller as typeof SubcategoryController) {
  static targets = ["modal", "list", "sort"];
  iso: Isotope;
  idx: string = "";

  connect() {
    this.iso = new Isotope(this.listTarget, {
      percentPosition: true,
      itemSelector: ".grid-item",
      getSortData: {
        price: "[data-price] parseFloat",
        name: "[data-name]",
        relevance: "[data-relevance] parseInt",
      },
    });
  }

  disconnect() {
    this.closeModal();
  }

  openModal(e: any) {
    e.preventDefault();
    this.modalTarget.classList.add("is-active");
    document.body.classList.add("overflow");
  }

  closeModal() {
    this.modalTarget.classList.remove("is-active");
    document.body.classList.remove("overflow");
  }

  sort(e: any) {
    const target = e.target as HTMLElement;
    this.iso.arrange({
      sortBy: target.dataset["sort"],
      sortAscending: target.dataset["sortAscending"] === "1",
    });
  }

  filter(key: string, minCurrent: number, maxCurrent: number) {
    this.iso.arrange({
      filter: (itemElement: any) => {
        const value = Number(itemElement.dataset[key]) || 0;
        return minCurrent <= value && value <= maxCurrent;
      },
    });
  }

  applyFilter(e: any, closeModal = true) {
    const target = e.target.closest(".btn") as HTMLElement;
    const cls = target.classList.contains("pc-device")
      ? "pc-device"
      : "mobile-device";
    const filters: {
      [key: string]: { min?: number; max?: number; items?: string[] };
    } = {};

    this.element
      .querySelectorAll(
        `.products-filters-container.${cls} .product-filter-checkbox`
      )
      .forEach((checkbox) => {
        const chb = checkbox as HTMLInputElement;
        if (chb.checked) {
          const value = chb.value;
          const key = chb.dataset["key"] || "subcategory";
          if (!filters[key]) filters[key] = { items: [] };
          filters[key].items!.push(value);
        }
      });

    this.element
      .querySelectorAll(`.products-filters-container.${cls} .range-container`)
      .forEach((range) => {
        const key = (range as HTMLElement).dataset["key"]!;
        const min = Number((range as HTMLElement).dataset["min"]!);
        const max = Number((range as HTMLElement).dataset["value"]!);
        filters[key] = { min, max };
      });

    this.iso.arrange({
      filter: (itemElement: any) => {
        return Object.keys(filters).every((key) => {
          const val = itemElement.dataset[key];
          if (filters[key].items != undefined) {
            return filters[key].items!.includes(val);
          }
          const numberValue = Number(val) || 0;
          return (
            filters[key].min! <= numberValue && numberValue <= filters[key].max!
          );
        });
      },
    });
    if (closeModal) this.closeModal();
  }

  resetFilters(e: any) {
    this.element.querySelectorAll(".range-container").forEach((range: any) => {
      const min = Number(range.dataset["min"]!) || 0;
      const max = Number(range.dataset["max"]) || min;
      range.dispatchEvent(
        new CustomEvent("rangeupdated", {
          detail: { value: max },
        })
      );
    });
    this.element
      .querySelectorAll(".product-filter-checkbox")
      .forEach((checkbox: any) => {
        checkbox.checked = true;
      });
    this.applyFilter(e, false);
  }

  toggleFilter(e: any) {
    const container = e.target.closest(".products-filter");
    const filter = container.querySelector(".product-filter");
    const minus = container.querySelector(".minus");
    const plus = container.querySelector(".plus");
    if (filter) {
      filter.classList.toggle("hidden");
      minus.classList.toggle("hidden", filter.classList.contains("hidden"));
      plus.classList.toggle("hidden", !filter.classList.contains("hidden"));
    }
  }

  toProductPage(e: any) {
    const target = e.target;
    if (!target) return;
    if (
      target.classList.contains(".favorite") ||
      target.closest(".favorite") ||
      target.classList.contains(".cart") ||
      target.closest(".cart") ||
      target.classList.contains(".tel") ||
      target.closest(".tel")
    )
      return;

    const container = target.closest(".grid-item");
    const url = container.dataset["href"];
    if (!url) return;

    window.location = url;
  }
}
