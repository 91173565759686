import { Controller } from "stimulus";

export default class extends Controller {
  boundResize: any;
  boundScroll: any;

  connect() {
    this.boundResize = this.onResize.bind(this);
    this.boundScroll = this.onScroll.bind(this);
    this.onResize();
    this.onScroll();
    window.addEventListener("resize", this.boundResize);
    window.addEventListener("scroll", this.boundScroll, { passive: true });

    if (window.location.hash) {
      this._scrollToElement(window.location.hash);
    }

    // Select all links with hashes
    document
      .querySelectorAll('a[href*="#"]:not([href="#"]):not([href="#0"])')
      .forEach((link) => {
        link.addEventListener("click", (event) => {
          // Figure out element to scroll to
          let target = event.target as HTMLElement;
          if (!target) return;
          target = (event.target as HTMLElement).closest("a") as HTMLElement;
          if (!target) return;
          const hash = target.getAttribute("href")!;
          this._scrollToElement(hash, event);
        });
      });
  }

  disconnect() {
    window.removeEventListener("resize", this.boundResize);
    window.removeEventListener("scroll", this.boundScroll);
  }

  onResize() {
    const navbar = this.element as HTMLElement;
    navbar.parentElement!.style.paddingTop = `${navbar.offsetHeight}px`;
  }

  onScroll() {
    const navbar = this.element as HTMLElement;
    const preHeader = document.querySelector(".pre-header")!;

    const condition = window.scrollY > 10;
    if (condition) {
      navbar.style.top = window.innerWidth < 1280 ? "0px" : "10px";
    } else {
      navbar.style.top = preHeader.clientHeight + "px";
    }
  }

  _scrollToElement(hash: string, event?: any) {
    let targets = document.querySelectorAll(hash);
    targets = targets.length
      ? targets
      : document.querySelectorAll("[name=" + hash.slice(1) + "]");
    // Does a scroll target exist?
    if (targets.length) {
      const target = targets[0] as HTMLElement;
      // Only prevent default if animation is actually gonna happen
      if (event) event.preventDefault();
      window.scrollTo({ top: target.offsetTop - 70, behavior: "smooth" });
    }
  }
}
