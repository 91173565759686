import { Controller } from "stimulus";

class CartButtonController extends Controller {
  public textTarget!: HTMLElement;
  public iconTarget: HTMLElement;
}

export default class extends (Controller as typeof CartButtonController) {
  addToCart(e: any) {
    e.preventDefault();
    const lang = document.body.dataset["lang"] || "ru";
    const button = this.element as HTMLElement;
    const productId = button.dataset["productId"];
    button.classList.add("disabled");
    fetch(`/${lang}/add_to_cart?product_id=${productId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const cartCount = document.querySelector("#cart-count") as HTMLElement;
        if (cartCount) {
          cartCount.innerText = data.count;
          cartCount.classList.toggle("hidden", data.count === 0);
        }
      })
      .catch((err) => console.warn("error", err))
      .finally(() => {
        if (button) {
          button.classList.remove("disabled");
        }

        const cartItems = document.querySelector(".cart-items") as HTMLElement;
        const cartInfoItems = document.querySelector(
          ".cart-info-items"
        ) as HTMLElement;
        if (cartItems) {
          const product = e.target.closest(".product-item") as HTMLElement;
          const product_ = cartItems.querySelector(
            ".product-item[data-item-id='" + productId + "']"
          ) as HTMLElement;
          if (product && !product_) {
            const cartItem = document.createElement("div");
            cartItem.dataset["action"] = "click->cart#toProductPage";
            cartItem.classList.add("cart-item");

            cartItem.innerHTML = `<div class="remove-from-cart" data-action="click->cart#removeFromCart">
                  <i class="fas fa-times text-primary text-2xl"></i>
                </div>`;

            const clone = product.cloneNode(true) as HTMLElement;

            cartItem.appendChild(clone);

            cartItem.innerHTML += `<div class="cart-item-count-container">
                  <div class="cart-item-count-btn decrease" data-action="click->cart#decreaseCount">
                    <i class="fas fa-minus text-primary"></i>
                  </div>
                  <div class="cart-item-count">1</div>
                  <div class="cart-item-count-btn increase" data-action="click->cart#increaseCount">
                    <i class="fas fa-plus text-primary"></i>
                  </div>
                </div>`;

            cartItems.appendChild(cartItem);

            if (cartInfoItems) {
              const product = e.target.closest(".product-item") as HTMLElement;
              if (product) {
                const cartInfoItem = document.createElement("div");
                cartInfoItem.classList.add("cart-info-item");
                cartInfoItem.dataset["itemId"] = product.dataset["productId"];
                cartInfoItem.innerHTML = `
                  <div class="cart-info-item-circle"></div>
                  <div class="cart-info-item-text">${product.dataset["title"]}</div>
                  <div class="cart-info-item-count">1 x</div>
                `;

                cartInfoItems.appendChild(cartInfoItem);
              }
            }

            const newCartItem = cartItems
              .querySelector(".product-item[data-item-id='" + productId + "']")
              ?.closest(".cart-item") as HTMLElement;
            const decrease = newCartItem?.querySelector(
              ".decrease"
            ) as HTMLElement;
            const increase = newCartItem?.querySelector(
              ".increase"
            ) as HTMLElement;

            setTimeout(() => {
              if (increase) increase.click();
              if (decrease) decrease.click();
            }, 100);
          }
        }
      });
  }

  removeFromCart(e: any) {
    e.preventDefault();
    const lang = document.body.dataset["lang"] || "ru";
    const button = this.element as HTMLElement;
    const productId = button.dataset["productId"];
    button.classList.add("disabled");
    fetch(`/${lang}/remove_from_cart?product_id=${productId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const item = this.element.closest(".product-item") as HTMLElement;
        if (item && item.parentElement) item.parentElement.removeChild(item);
        const cartCount = document.querySelector("#cart-count") as HTMLElement;
        if (cartCount) {
          cartCount.innerText = data.count;
        }
      })
      .catch((err) => console.warn("error"))
      .finally(() => {
        if (button) {
          button.classList.remove("disabled");
        }
      });
  }
}
