// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

class PromotionsSliderController extends Controller {
  public trackTarget!: HTMLElement;
  public listTarget!: HTMLElement;
}

export default class extends (Controller as typeof PromotionsSliderController) {
  static targets = ["track", "list"];

  splide: Splide | undefined;
  boundResize: any;

  connect() {
    this.initSlider();
    this.boundResize = this.onResize.bind(this);
    this.onResize();
    window.addEventListener("resize", this.boundResize);
  }

  initSlider() {
    const container = (this.element as HTMLElement).querySelector(
      ".splide"
    ) as HTMLElement;
    const itemsLength = container.querySelectorAll(".splide__slide").length;

    const getOptionsForPage = (perPage: number) => {
      return {
        perPage: perPage > itemsLength ? itemsLength : perPage,
        type: perPage > itemsLength ? "slide" : "loop",
      };
    };

    this.splide = new Splide(container, {
      perMove: 1,
      autoplay: true,
      pagination: false,
      pauseOnHover: true,
      speed: 2000,
      interval: 5000,

      ...getOptionsForPage(3),

      breakpoints: {
        820: getOptionsForPage(2),
        990: getOptionsForPage(3),
        1200: getOptionsForPage(3),
        1550: getOptionsForPage(3),
      },
    }).mount();
  }

  onResize() {
    this.splide?.refresh();

    const container = (this.element as HTMLElement).querySelector(
      ".splide"
    ) as HTMLElement;
    const itemsLength = container.querySelectorAll(".splide__slide").length;

    let perPage = 6;
    if (window.innerWidth < 820) {
      perPage = 2;
    } else if (window.innerWidth < 990) {
      perPage = 3;
    } else if (window.innerWidth < 1200) {
      perPage = 4;
    } else if (window.innerWidth < 1550) {
      perPage = 5;
    }

    const condition = itemsLength > perPage;

    container
      .querySelector(".splide__arrows")
      ?.classList.toggle("hidden", !condition);
  }
}
