// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Turbolinks from "turbolinks";
import "../channels/index";
import "../styles/application.scss";

Turbolinks.start();

import "animate.css";

import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import "video.js/dist/video-js.min.css";

import "../controllers/client";
