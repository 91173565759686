import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

export default class extends Controller {
  productsSplide: Splide | undefined;
  videosSplide: Splide | undefined;
  boundResize: any;

  connect() {
    this.initProductsSlider();
    this.initVideosSlider();
    this.boundResize = this.onResize.bind(this);
    this.onResize();
    window.addEventListener("resize", this.boundResize);
  }

  initProductsSlider() {
    const container = this.element.querySelector(
      ".favorite-products-slider"
    ) as HTMLElement;

    if (container) {
      const itemsLength = container.querySelectorAll(".splide__slide").length;

      const getOptionsForPage = (perPage: number) => {
        return {
          perPage: perPage > itemsLength ? itemsLength : perPage,
          type: perPage > itemsLength ? "slide" : "loop",
        };
      };

      this.productsSplide = new Splide(container, {
        perMove: 1,
        autoplay: true,
        pagination: false,
        pauseOnHover: true,
        speed: 2000,
        interval: 5000,
        ...getOptionsForPage(6),

        breakpoints: {
          820: getOptionsForPage(2),
          990: getOptionsForPage(3),
          1200: getOptionsForPage(4),
          1550: getOptionsForPage(5),
        },
      }).mount();
    }
  }

  initVideosSlider() {
    const container = this.element.querySelector(
      ".favorite-videos-slider"
    ) as HTMLElement;

    if (container) {
      const itemsLength = container.querySelectorAll(".splide__slide").length;

      const getOptionsForPage = (perPage: number) => {
        return {
          perPage: perPage > itemsLength ? itemsLength : perPage,
          type: perPage > itemsLength ? "slide" : "loop",
        };
      };

      this.videosSplide = new Splide(container, {
        perMove: 1,
        autoplay: true,
        pagination: false,
        pauseOnHover: true,
        speed: 2000,
        interval: 5000,
        clones: 0,

        ...getOptionsForPage(2),

        breakpoints: {
          820: getOptionsForPage(1),
          990: getOptionsForPage(1),
          1200: getOptionsForPage(1),
          1550: getOptionsForPage(2),
        },
      }).mount();
    }
  }

  onResize() {
    this.productsSplide?.refresh();
    this.videosSplide?.refresh();

    const productsContainer = this.element.querySelector(
      ".favorite-products-slider"
    ) as HTMLElement;

    if (productsContainer) {
      const itemsLength =
        productsContainer.querySelectorAll(".splide__slide").length;

      let perPage = 6;
      if (window.innerWidth < 820) {
        perPage = 2;
      } else if (window.innerWidth < 990) {
        perPage = 3;
      } else if (window.innerWidth < 1200) {
        perPage = 4;
      } else if (window.innerWidth < 1550) {
        perPage = 5;
      }

      const condition = itemsLength > perPage;

      productsContainer
        .querySelector(".splide__arrows")
        ?.classList.toggle("hidden", !condition);
    }

    const videosContainer = this.element.querySelector(
      ".favorite-videos-slider"
    ) as HTMLElement;

    if (videosContainer) {
      const itemsLength =
        videosContainer.querySelectorAll(".splide__slide").length;

      let perPage = 2;
      if (window.innerWidth < 1550) {
        perPage = 1;
      }

      const condition = itemsLength > perPage;

      videosContainer
        .querySelector(".splide__arrows")
        ?.classList.toggle("hidden", !condition);
    }
  }

  toProductPage(e: any) {
    const target = e.target;
    if (!target) return;
    if (
      target.classList.contains(".cart") ||
      target.closest(".cart") ||
      target.classList.contains(".favorite") ||
      target.closest(".favorite") ||
      target.classList.contains(".tel") ||
      target.closest(".tel") ||
      target.classList.contains(".remove-from-favorite") ||
      target.closest(".remove-from-favorite")
    )
      return;

    const container =
      target.closest(".cart-item .product-item") ||
      target.closest(".splide__slide");
    if (!container) return;
    const url = container.dataset["href"];
    if (!url) return;

    window.location = url;
  }

  removeFromFavorite(e: any) {
    e.preventDefault();
    const removeFromFavorite = e.target.closest(".remove-from-favorite");
    let type = removeFromFavorite.dataset["type"] || "product";
    type = type === "video" ? "video" : "product";
    const lang = document.body.dataset["lang"] || "ru";
    const item = e.target.closest(".splide__slide") as HTMLElement;
    const product = item?.querySelector(".product-item") as HTMLElement;
    const video = item?.querySelector(".video-item") as HTMLElement;

    const productId = product
      ? product.dataset["itemId"]
      : video
      ? video.dataset["itemId"]
      : null;
    if (!productId) return;

    fetch(
      `/${lang}/remove_from_favorite?product_id=${productId}&type=${type}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const parentElement = item.parentElement as HTMLElement;
        if (parentElement) {
          parentElement
            .querySelectorAll(
              ".product-item[data-item-id='" +
                productId +
                "']," +
                " .video-item[data-item-id='" +
                productId +
                "']"
            )
            .forEach((el) => {
              const splideItem = el.closest(".splide__slide") as HTMLElement;
              parentElement.removeChild(splideItem);
            });
        }
        this.onResize();
      })
      .catch((err) => console.warn("error", err));
  }
}
