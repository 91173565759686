import { Controller } from "stimulus"

export default class extends Controller {
  connect() {}

  toggle() {
    const html = document.querySelector('html') as HTMLElement
    html.classList.toggle('_billet_active')
    document.querySelectorAll('.billet-pulse').forEach(pulse => {
      pulse.classList.toggle('pulse', !html.classList.contains('_billet_active'))
    })
  }
}
