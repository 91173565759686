import { Controller } from "stimulus";

class FavoriteButtonController extends Controller {
  public textTarget!: HTMLElement;
  public iconTarget: HTMLElement;
}

export default class extends (Controller as typeof FavoriteButtonController) {
  addToFavorite(e: any) {
    e.preventDefault();
    const lang = document.body.dataset["lang"] || "ru";
    const button = this.element as HTMLElement;
    const productId = button.dataset["productId"];
    let type = button.dataset["type"] || "product";
    type = type === "video" ? "video" : "product";
    if (!productId) return;

    button.classList.add("disabled");
    fetch(`/${lang}/add_to_favorite?product_id=${productId}&type=${type}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .catch((err) => console.warn("error", err))
      .finally(() => {
        if (button) {
          button.classList.remove("disabled");
        }
      });
  }

  removeFromFavorite(e: any) {
    e.preventDefault();
    const lang = document.body.dataset["lang"] || "ru";
    const button = this.element as HTMLElement;
    const productId = button.dataset["productId"];
    let type = button.dataset["type"] || "product";
    type = type === "video" ? "video" : "video";
    if (!productId) return;

    button.classList.add("disabled");
    fetch(
      `/${lang}/remove_from_favorite?product_id=${productId}&type=${type}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.warn("error"))
      .finally(() => {
        if (button) {
          button.classList.remove("disabled");
        }
      });
  }
}
