import { Controller } from "stimulus";
import Splide from "@splidejs/splide";

class ProductController extends Controller {
  public mainTarget!: HTMLElement;
  public thumbnailsTarget!: HTMLElement;

  public formTarget!: HTMLFormElement;
  public nameInputTarget!: HTMLInputElement;
  public textInputTarget!: HTMLTextAreaElement;
  public submitButtonTarget!: HTMLButtonElement;

  public similarProductsTarget!: HTMLElement;
  public commentsTarget!: HTMLElement;
  public commentRatingTarget!: HTMLElement;
}

export default class extends (Controller as typeof ProductController) {
  static targets = [
    "main",
    "thumbnails",
    "form",
    "nameInput",
    "textInput",
    "submitButton",
    "similarProducts",
    "comments",
    "commentRating",
  ];

  similarSplide: Splide | undefined;
  boundResize: any;

  connect() {
    this.initPhotosSlider();
    this.initSimilarSlider();
    this.initCommentsSlider();
    this.initRatings();

    this.boundResize = this.onResize.bind(this);
    this.onResize();
    window.addEventListener("resize", this.boundResize);

    const container = document.querySelector(".product-page") as HTMLElement;
    if (container) {
      const productId = container.dataset["itemId"];
      if (productId) {
        const count = localStorage.getItem(`cart-count-${productId}`);
        if (count) {
          this.updateProductCount(productId, Number(count));
        }
      }
    }
  }

  createComment(e: Event) {
    const name = this.nameInputTarget.value.trim();
    const text = this.textInputTarget.value.trim();

    if (!name || !text) {
      e.preventDefault();
      return false;
    }

    return true;
  }

  setRating(e: Event) {
    const target = e.target as HTMLElement;
    const input = this.commentRatingTarget.querySelector("input")!;
    const value = target.dataset["value"] || "0";
    input.value = value;

    this.commentRatingTarget.querySelectorAll("i").forEach((rating) => {
      if (Number(rating.dataset["value"]) <= Number(value)) {
        rating.classList.remove("far");
        rating.classList.add("fas");
      } else {
        rating.classList.remove("fas");
        rating.classList.add("far");
      }
    });
  }

  toProductPage(e: any) {
    const target = e.target;
    if (!target) return;
    if (
      target.classList.contains(".favorite") ||
      target.closest(".favorite") ||
      target.classList.contains(".cart") ||
      target.closest(".cart") ||
      target.classList.contains(".tel") ||
      target.closest(".tel")
    )
      return;

    const container = target.closest(".splide__slide");
    const url = container.dataset["href"];
    if (!url) return;

    window.location = url;
  }

  initPhotosSlider() {
    const container = this.mainTarget;
    const main = new Splide(container, {
      type: "fade",
      autoplay: true,
      pauseOnHover: true,
      pauseOnFocus: true,
      speed: 1500,
      interval: 6000,
      pagination: false,
      arrows: false,
    });
    const thumbnails = new Splide(this.thumbnailsTarget, {
      rewind: true,
      fixedWidth: 104,
      fixedHeight: 58,
      isNavigation: true,
      gap: 10,
      focus: "center",
      pagination: false,
      cover: true,
      breakpoints: {
        640: {
          fixedWidth: 66,
          fixedHeight: 38,
        },
      },
    });
    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
    if (container.querySelectorAll(".splide__slide").length < 2) {
      this.thumbnailsTarget.classList.add("hidden");
    }
  }

  initSimilarSlider() {
    const container = this.similarProductsTarget;
    if (container) {
      const itemsLength = container.querySelectorAll(".splide__slide").length;
      const getOptionsForPage = (perPage: number) => {
        return {
          perPage: perPage > itemsLength ? itemsLength : perPage,
          type: perPage > itemsLength ? "slide" : "loop",
        };
      };

      this.similarSplide = new Splide(container, {
        perMove: 1,
        autoplay: true,
        arrows: true,
        pagination: false,
        pauseOnHover: true,
        speed: 2000,
        interval: 5000,

        ...getOptionsForPage(4),

        breakpoints: {
          640: getOptionsForPage(1),
          820: getOptionsForPage(2),
          990: getOptionsForPage(3),
          1200: getOptionsForPage(3),
        },
      }).mount();
    }
  }

  initCommentsSlider() {
    const container = this.commentsTarget;
    if (container) {
      const itemsLength = container.querySelectorAll(".splide__slide").length;
      const getOptionsForPage = (perPage: number) => {
        return {
          perPage: perPage > itemsLength ? itemsLength : perPage,
          type: perPage > itemsLength ? "slide" : "loop",
        };
      };

      new Splide(container, {
        perMove: 1,
        autoplay: true,
        arrows: true,
        pagination: false,
        pauseOnHover: true,
        speed: 2000,
        interval: 5000,

        ...getOptionsForPage(2),

        breakpoints: {
          1350: getOptionsForPage(1),
          1550: getOptionsForPage(2),
        },
      }).mount();
    }
  }

  initRatings() {
    const ratings = document.querySelectorAll(
      ".rating-star"
    ) as NodeListOf<HTMLElement>;
    ratings.forEach((rating) => {
      rating.addEventListener("mouseover", (e) => {
        const value = rating.dataset["value"];
        ratings.forEach((r) => {
          if (Number(r.dataset["value"]) <= Number(value)) {
            r.classList.remove("far");
            r.classList.add("fas");
          } else {
            r.classList.remove("fas");
            r.classList.add("far");
          }
        });
      });
      rating.addEventListener("mouseout", (e) => {
        const input = this.commentRatingTarget.querySelector("input")!;
        ratings.forEach((r) => {
          if (Number(r.dataset["value"]) <= Number(input.value)) {
            r.classList.remove("far");
            r.classList.add("fas");
          } else {
            r.classList.remove("fas");
            r.classList.add("far");
          }
        });
      });
    });
  }

  onResize() {
    this.similarSplide?.refresh();

    const similarContainer = this.similarProductsTarget;
    if (similarContainer) {
      const itemsLength =
        similarContainer.querySelectorAll(".splide__slide").length;

      let perPage = 3;
      if (window.innerWidth < 640) {
        perPage = 1;
      } else if (window.innerWidth < 820) {
        perPage = 2;
      } else if (window.innerWidth < 990) {
        perPage = 3;
      } else if (window.innerWidth < 1200) {
        perPage = 3;
      } else if (window.innerWidth < 1550) {
        perPage = 3;
      }

      const condition = itemsLength > perPage;

      similarContainer
        .querySelector(".splide__arrows")
        ?.classList.toggle("hidden", !condition);
    }

    const commentsContainer = this.commentsTarget;
    if (commentsContainer) {
      const itemsLength =
        commentsContainer.querySelectorAll(".splide__slide").length;

      let perPage = 2;
      if (window.innerWidth < 820) {
        perPage = 1;
      } else if (window.innerWidth < 990) {
        perPage = 2;
      } else if (window.innerWidth < 1200) {
        perPage = 2;
      } else if (window.innerWidth < 1550) {
        perPage = 2;
      }

      const condition = itemsLength > perPage;

      commentsContainer
        .querySelector(".splide__arrows")
        ?.classList.toggle("hidden", !condition);
    }
  }

  toggleZoom(e: Event) {
    const target = e.target as HTMLElement;
    if (!target) return;

    const container = target.closest(".product-grid__photos_footer");
    if (!container) return;

    const photos = container.closest(".product-grid__photos");
    const textElement = container.querySelector("span") as HTMLSpanElement;

    const isActive = !!photos?.classList.contains("active");

    photos?.classList.toggle("active", !isActive);
    if (textElement) {
      textElement.innerText =
        textElement.dataset[isActive ? "zoomin" : "zoomout"] || "";
    }

    const html = document.querySelector("html");
    html?.classList.toggle("product-zoom", !isActive);
  }

  increaseCount(e: any) {
    e.preventDefault();
    const product = document.querySelector(".product-page") as HTMLElement;
    if (!product) return;

    const productId = product.dataset["itemId"];
    if (!productId) return;

    let count = Number(product.dataset["itemCount"]);
    if (isNaN(count) || count < 1) {
      count = 1;
    } else {
      count++;
    }

    this.updateProductCount(productId, count);
  }

  decreaseCount(e: any) {
    e.preventDefault();
    const product = document.querySelector(".product-page") as HTMLElement;
    if (!product) return;

    const productId = product.dataset["itemId"];
    if (!productId) return;

    let count = Number(product.dataset["itemCount"]);
    if (isNaN(count) || count <= 1) {
      count = 1;
    } else {
      count--;
    }

    this.updateProductCount(productId, count);
  }

  private updateProductCount(productId: string, count: number) {
    const product = document.querySelector(".product-page") as HTMLElement;
    let newCount = Number(count);
    if (isNaN(newCount) || newCount <= 1) {
      newCount = 1;
    }

    const countElement = document.querySelector(
      ".product-grid-count"
    ) as HTMLElement;
    if (!countElement) return;

    countElement.innerText = newCount.toString();
    product.dataset["itemCount"] = newCount.toString();
    localStorage.setItem(`cart-count-${productId}`, newCount.toString());
  }

  toggleTab(e: any) {
    const tab = e.target as HTMLElement;
    if (!tab || tab.classList.contains("active")) return;

    document.querySelectorAll(".product-grid__description_tab").forEach((t) => {
      t.classList.remove("active");
    });

    const description = document.querySelector(
      ".product-grid__description-description"
    ) as HTMLElement;
    const characteristics = document.querySelector(
      ".product-grid__description-characteristics"
    ) as HTMLElement;

    const isDescriptionTab = tab.classList.contains(
      "product-grid__description_tab-description"
    );

    description.classList.toggle("hidden", !isDescriptionTab);
    characteristics.classList.toggle("hidden", isDescriptionTab);

    tab.classList.toggle("active");
  }
}
