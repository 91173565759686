import { Controller } from "stimulus"

class SharedDropdownController extends Controller {
  public listTarget!: HTMLElement
  public labelTarget!: HTMLElement
  public itemTargets!: HTMLElement[]
}

export default class extends (Controller as typeof SharedDropdownController) {
  static targets = ['label', 'list', 'item']
  boundDropDownClick: any

  connect() {
    this.boundDropDownClick = this.dropDownClick.bind(this)
    document.body.addEventListener('click', this.boundDropDownClick)
  }

  disconnect() {
    document.body.removeEventListener('click', this.boundDropDownClick)
  }

  dropDownClick(e: any) {
    const target = e.target as HTMLElement
    const container = target.closest('.dropdown-button')
    const item = target.closest('.dropdown-item')

    if (item || target.classList.contains('dropdown-item')) {
      this.itemTargets.forEach(item => {
        item.classList.remove('bg-gray-100', 'text-primary', 'is-active')
        item.classList.add('text-gray-700')
      })
      target.classList.remove('text-gray-700')
      target.classList.add('bg-gray-100', 'text-primary', 'is-active')
      e.preventDefault()
      this.toggleDropdown(true)
      this.labelTarget.innerText = target.innerText
    } else if (container || target.classList.contains('dropdown-button')) {
      this.toggleDropdown()
    } else {
      this.toggleDropdown(true)
    }
  }

  toggleDropdown(value: boolean | null = null) {
    value = value === null ? !this.listTarget.classList.contains('hidden') : value
    this.listTarget.classList.toggle('hidden', value)
  }
}
