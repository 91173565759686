// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.ts.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Quill from "quill";

const application = Application.start();
const context = require.context("controllers/client", true, /_controller\.ts$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbolinks:load", () => {
  const options = {
    readOnly: true,
    modules: {
      toolbar: null,
    },
    theme: "snow",
  };
  const quillElements = document.querySelectorAll(
    ".ql-preview"
  ) as NodeListOf<HTMLElement>;
  quillElements.forEach((element) => {
    new Quill(element, options);
  });
});
